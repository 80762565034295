import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TestImage2 from "assets/images/test-image-2.svg";
import CloseIcon from "assets/svgs/close-btn.svg";
import RichText from "components/rich-text";
import getImagePath from "helpers/get-image-path";

import {
  customerKnife, modalClose, customerKnifeImage,
  customerKnifeBody, customerKnifeHead,
  customerModal, modalCloseDesktop, modalCloseResponsive,
  customerKnifeSvg, customerKnifeSvgHandle,
  customerKnifeSvgResponsive, customerKnifeSvgHandleResponsive, customServiceModal, modalContainer
} from "./style.mod.scss";

const customerKnifeH = ({ data, children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mData = data?.modal && data.modal[0];
  if (!data || !mData) return <></>;

  return (
    <div className={customerModal + " " + customerKnife}>
      <div className={modalContainer} onClick={handleShow}>
        { children }
      </div>

      <Modal show={show} onHide={handleClose} className={customServiceModal}>
        <CloseIcon className={`${modalClose} ${modalCloseDesktop}`} onClick={handleClose} />
        <Modal.Body>
          <CloseIcon className={`${modalClose} ${modalCloseResponsive}`} onClick={handleClose} />
          <div className={customerKnifeBody}>
            <div className={customerKnifeHead}>
              <RichText
                blocks={mData.title}
              />
              <div className={`${customerKnifeSvgHandle} ${customerKnifeSvgHandleResponsive}`}>
                <img src={TestImage2} className={`${customerKnifeSvg} ${customerKnifeSvgResponsive}`} />
              </div>
            </div>
            <div className={customerKnifeSvgHandle}>
              <img src={TestImage2} className={customerKnifeSvg} />
            </div>
          </div>
          <img srcSet={getImagePath(mData.image, true)} alt={mData?.image?.alt} className={customerKnifeImage}/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default customerKnifeH;
