// extracted by mini-css-extract-plugin
export var customServiceModal = "style-mod--custom-service-modal--DY3kY";
export var modalClose = "style-mod--modal-close--39ioQ";
export var modalCloseDesktop = "style-mod--modal-close-desktop--knOQu";
export var modalCloseDesktopDesktop = "style-mod--modal-close-desktop-desktop--3YsPv";
export var modalCloseDesktopResponsive = "style-mod--modal-close-desktop-responsive--66SSa";
export var modalCloseResponsive = "style-mod--modal-close-responsive--lpd31";
export var modalParagraph = "style-mod--modal-paragraph--3XG-e";
export var modalTitle = "style-mod--modal-title--uFd3t";
export var customerKnifeSvg = "style-mod--customer-knife-svg--23ZAp";
export var customerKnifeSvgResponsive = "style-mod--customer-knife-svg-responsive--3t5YD";
export var customerKnifeSvgHandle = "style-mod--customer-knife-svg-handle--2Dy3H";
export var customerKnifeSvgHandleResponsive = "style-mod--customer-knife-svg-handle-responsive--OUNb-";
export var customerKnifeTitle = "style-mod--customer-knife-title--1sFt7";
export var customerKnifeHead = "style-mod--customer-knife-head--QH9z7";
export var customerKnifeBody = "style-mod--customer-knife-body--23Fol";
export var customerKnifeImage = "style-mod--customer-knife-image--2eOSM";